<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.582 5.08341L10.7487 3.41675H4.08203V17.5834H5.7487V11.7501H9.91536L10.7487 13.4167H16.582V5.08341H11.582ZM14.9154 11.7501H11.582L10.7487 10.0834H5.7487V5.08341H9.91536L10.7487 6.75008H14.9154V11.7501Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcFlag',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
